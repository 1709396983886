header nav {
    padding: .5rem;
    position: fixed;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    gap: .8rem;
    background-color: var(--main-bgColorLightGrey);
    border: 4px double black;
}

header nav a {
    font-size: .83rem;
    font-weight: normal;
    font-family: var(--main-fontFamily-secondTitle);
    border-radius: 10%;
    padding: .5rem;
    box-shadow: .1rem .1rem .2rem black;
    background-color: #000;
    transition: all ease .3s;
    color: white;
    text-decoration: none;
    text-align: center;
}

header nav a:visited {
    color: #ffffff;
}

header nav a:hover {
    box-shadow: 4px 3px 3px #000;
    text-shadow: none;
    transform: scale(1.01);
    background: #ff3030;
    border: 2px solid #000;
    color: black;
}

header nav a:hover svg {
    color: #000;
}

/* Mobile area */

.nav-btn {
    padding: 5px;
    background: transparent;
    border: none;
    outline: none;
    color: black;
    display: none;
    opacity: 0;
    font-size: 2rem;
    position: fixed;
    z-index: 2;
    width: 100vw;
    text-align: left;
}

a.-btn-ancorToJavaBibleScreen {
    padding: 0 0 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

a.-btn-ancorToJavaBibleScreen > svg {
    font-size: 2rem;
    color: red;
}


@keyframes animaIconPisca {
    0% { 
        transform: scale(1.3) rotate(3.142rad);
    }
    30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.3);
    }
    100% { 
        transform: scale(1) rotate(0); 
    }
}
.animaIconPisca {
  color: red;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px; 
  -webkit-animation: animaIconPisca 1.5s linear infinite;
  -moz-animation: animaIconPisca 1.5s linear infinite;
  -ms-animation: animaIconPisca 1.5s linear infinite;
  -o-animation: animaIconPisca 1.5s linear infinite;
  animation: animaIconPisca 1.5s linear infinite;
}

.animaIconPisca > svg::after {
   content: "+1";
   color: #000;
   font-size: .9rem;
   font-family: 'press start 2p';
}
  


@keyframes animaIconPisca--alert {
    0% { 
        transform: scale(1.8) translateX(0);
        color: red;
        opacity: 1;
    }
    
    100% { 
        transform: scale(0) translateX(-5rem); 
        /* color: transparent; */
        opacity: 0;
    }
}

.animaIconPisca--alert {
    display: inline-block;
    text-decoration: none;
    border-radius: 4px; 
    -webkit-animation: animaIconPisca--alert 2s linear infinite;
    -moz-animation: animaIconPisca--alert 2s linear infinite;
    -ms-animation: animaIconPisca--alert 2s linear infinite;
    -o-animation: animaIconPisca--alert 2s linear infinite;
    animation: animaIconPisca--alert 2s linear infinite;
}

.visibilityhidden {
    visibility: hidden;
}


/* Audio Player Area */

#audioPlayer {
    display: none;
}

div.audioPlayer--container.--audioPlayer--container.--navBar {
    margin-left: auto;
}

ul#playlist-audioPlayer {
    list-style: none;

}

ul#playlist-audioPlayer li a {
    color: #000;
    text-decoration: none;
}

#playlist-audioPlayer .current-song a {
    color: blue;
}

.audioPlayer-progressBar--duration {
    width: 100%;
    max-width: 5rem;
    margin-top: 1px;
}

/* .audioPlayer-progressBar--fillBar {
    border: 1px solid black;
} */

.audioPlayer-progressBar--fillBar progress[value] {
    height: 6px;
    width: 100%;
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
}

.audioPlayer-progressBar--fillBar progress {
    /* Altera a cor do background no Firefox e navegadores da Microsoft */
    background: #000;
  
    /* É necessário alterar o valor para none */
    -webkit-appearance: none;

    border-radius: 3px;
  }
  
/* Altera a cor de fundo em navegadores com webkit (Chrome, Safari etc) */
.audioPlayer-progressBar--fillBar progress::-webkit-progress-value {
background-color: #000;
}

/* Altera a cor da barra em navegadores com webkit (Chrome, Safari etc) */
.audioPlayer-progressBar--fillBar progress::-webkit-progress-bar {
background-color: #000;
}

/* Altera a cor da barra em navegadores com moz (Firefox) */
.audioPlayer-progressBar--fillBar progress::-moz-progress-bar {
background-color: #000;
}

/* Altera a cor da barra em navegadores da Microsoft (IE e Edge) */
.audioPlayer-progressBar--fillBar progress::-ms-fill {
background-color: #000;
}


.audioPlayer--controls i > svg {
    font-size: 1.5rem;
    cursor: pointer;
    color: red;
    background-color: black;
    border-radius: 50%;
    margin-right: 2px;
}

.audioPlayer--controls i > svg:hover {
    transform: scale(1.1);
}

.audioPlayer-control--play.isActiv, .audioPlayer-control--pause.isActiv{
    display: none;
}

.audioPlayer--controls {
    text-align: center;
    font-size: 0;
}

.audioPlayer--container.--btnHamburguerResponsive > .audioPlayer-progressBar--duration{
    margin-top: 2px;
}

.audioPlayer--container.--btnHamburguerResponsive {
    margin-left: auto;
}

@media (max-width: 810px)
{
    .nav-btn {
        display: flex;
        opacity: 1;
    }

    #btn-hamburguer {
        background-color: var(--main-bgColorLightGrey);
        border: 4px double black;
        border-radius: 10px;
        top: 0;
        left: 0;
    }

    button#btn-hamburguer > svg, button#nav-close-btn > svg {
        cursor: pointer;
    }

    #main-projects--container, #main-certificate--container {
        padding-left: 0 !important;
    }

    /*#btn-hamburguer::after {
        content: '';
        background-color: white;
        border: 4px double black;
        border-radius: 10px;
        width: 100%;
        position: absolute;
        width: 95vw;
        height: 100%;
    }*/

    .nav-btn.nav-close-btn {
        position: relative;
        width: fit-content;
        margin-left: auto;
        transform: translateX(1rem);
        padding: 5px 5px 0 0;
    }

    .nav-btn.nav-close-btn > svg  {
        background-color: #ff3030;
        border-radius: 50%;
        border: 2px solid black;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column !important;    
        gap: .5rem;
        transition: all ease 1s;
        transform: translateX(-150vw);
        background-color: var(--main-bgColorLightGrey);
        border: 4px double black;
        width: inherit !important;
        padding: 0 1rem 1rem 1rem;
        z-index: 3;
    }

    header nav a {
        width: 100% !important;
        text-align: center;
        margin: 0 auto;
        font-size: .75rem;
    }

    .ajustaTop-responsive{
        margin-top: -1rem;
    }

    header .responsive_nav {
        transform: none;
    }

    .responsive_nav-opacityUm {
        opacity: 1;
    }

    .responsive_nav-opacityZero {
        opacity: 0;
    }

    .--audioPlayer--container.--navBar {
        margin: auto;
    }

    .audioPlayer-progressBar--fillBar {
        font-size: 0 !important;
        margin-top: 2px;
    }

    /* .audioPlayer--container.--btnHamburguerResponsive {
        font-size: 0;
    } */
}


@media (max-width: 400px) 
{
    header nav a {
        font-size: .7rem;
    }
}