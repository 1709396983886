/*.wrapper-skills {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 80%;
}

.skill-tec {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    max-width: 30%;
}

.icon {
    max-width: 6em;
    max-height: 6em;

} */

#main-skills--container {
    min-height: 70vh;
    width: 100%;
}


.wrapper-skills {
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    justify-content: space-around;
    margin: 0 auto;
    gap: 2rem;
    align-content: center;
    padding-bottom: 1rem;
}

.icon {
    width: 4.5rem;
    height: 4.5rem;
    background-color: white;
    border-radius: 1rem;
    padding: .2rem;
    box-shadow: 2px 2px 3px black;
}

.skill-tec {
   display: flex;
   align-items: center;
}

.skill-tec div progress {
    width: 8rem;
    height: 1rem;
}

.right {
    margin: 0 0 0 .5rem;
    font-size: .75rem;
    font-weight: normal;
    font-family: var(--main-fontFamily-secondTitle);
    text-shadow: .1rem .1rem 0.08rem black;
    color: white;
    display: flex;
    flex-direction: column;
}

.title-skill {
    font-size: 0.7rem;
}

.skill-tec > .right > span{
    color: #ffd700;
    font-size: .7rem;
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    background-color: black;
    width: max-content;
    margin: 0 auto;
    padding: 1px 3px;
    border-radius: 5px;
    margin-bottom: 3px;
}

progress:not(value) {
    /* Add your styles here. As part of this walkthrough we will focus only on determinate progress bars. */
}

.progress-bar.--skills {
    background-color: #11e8ff;
}  
/* Styling the determinate progress element */
  
progress.progress-bar.--skills[value] {
    /* Get rid of the default appearance */
    appearance: none;
    
    /* This unfortunately leaves a trail of border behind in Firefox and Opera. We can emove that by setting the border to none. */
    border: none;
    
    /* Add dimensions */
    width: 8rem; height: .9rem;
    
    /* Although firefox doesn't provide any additional pseudo class to style the progress element container, any style applied here works on the container. */
      background-color: whiteSmoke;
      border-radius: 3px;
      box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
    
    /* Of all IE, only IE10 supports progress element that too partially. It only allows to change the background-color of the progress value using the 'color' attribute. */
    color: royalblue;
    
    position: relative;
    margin: 0 0 4px; 
}
  
  /*
  Webkit browsers provide two pseudo classes that can be use to style HTML5 progress element.
  -webkit-progress-bar -> To style the progress element container
  -webkit-progress-value -> To style the progress element value.
  */
  
 progress[value]::-webkit-progress-bar {
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
 }
  
 progress[value]::-webkit-progress-value {
    position: relative;
    
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
    
    /* Let's animate this */
    animation: animate-stripes 5s linear infinite;
}


  
@keyframes animate-stripes { 100% { background-position: -100px 0; } }
  
  /* Let's spice up things little bit by using pseudo elements. */
  
progress[value]::-webkit-progress-value:after {
    /* Only webkit/blink browsers understand pseudo elements on pseudo classes. A rare phenomenon! */
    content: '';
    position: absolute;
    
    width:5px; height:5px;
    top:7px; right:7px;
    
    background-color: white;
    border-radius: 100%;
 }
  
  /* Firefox provides a single pseudo class to style the progress element value and not for container. -moz-progress-bar */
  
  progress.progress-bar.--skills[value]::-moz-progress-bar {
    /* Gradient background with Stripes */
    background-image:
    -moz-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -moz-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -moz-linear-gradient( left, rgb(0, 195, 255), rgb(0, 153, 255));
    
    background-size: 35px 20px, 100% 100%, 100% 100%;
    border-radius:3px;
    
    /* Firefox doesn't support CSS3 keyframe animations on progress element. Hence, we did not include animate-stripes in this code block */
}
  
  /* Fallback technique styles */
.progress-bar {
    background-color: whiteSmoke;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
  
    /* Dimensions should be similar to the parent progress element. */
    width: 100%; height:20px;
}
  
/*.progress-bar span {
    background-color: royalblue;
    border-radius: 3px;
    
    display: block;
    text-indent: -9999px;
}
  
p[data-value] { 
    
    position: relative; 
}*/
  
  /* The percentage will automatically fall in place as soon as we make the width fluid. Now making widths fluid. */
  
/*p[data-value]:after {
    content: attr(data-value) '%';
    position: absolute; right:0;
}*/
  
  
  
  
  
.html5::-webkit-progress-value,
.python::-webkit-progress-value  {
    /* Gradient background with Stripes */
    background-image:
    -webkit-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -webkit-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -webkit-linear-gradient( left, #09c, #f44);
}
  
  .css3::-webkit-progress-value,
  .php::-webkit-progress-value 
{
    /* Gradient background with Stripes */
    background-image:
    -webkit-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -webkit-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -webkit-linear-gradient( left, #09c, rgb(255, 0, 0));
}
  
.jquery::-webkit-progress-value,
.node-js::-webkit-progress-value {
    /* Gradient background with Stripes */
    background-image:
    -webkit-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -webkit-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -webkit-linear-gradient( left, #09c, rgb(153, 0, 0));
}
  
  /* Similarly, for Mozillaa. Unfortunately combining the styles for different browsers will break every other browser. Hence, we need a separate block. */
  
.html5::-moz-progress-bar,
.php::-moz-progress-bar {
    /* Gradient background with Stripes */
    background-image:
    -moz-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -moz-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -moz-linear-gradient( left, #09c, #f44);
  }
  
.css3::-moz-progress-bar,
.php::-moz-progress-bar {
    /* Gradient background with Stripes */
    background-image:
    -moz-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -moz-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -moz-linear-gradient( left, #09c, #ff0);
}
  
.jquery::-moz-progress-bar,
.node-js::-moz-progress-bar {
    /* Gradient background with Stripes */
    background-image:
    -moz-linear-gradient( 135deg,
                             transparent,
                             transparent 33%,
                             rgba(0,0,0,.1) 33%,
                             rgba(0,0,0,.1) 66%,
                             transparent 66%),
      -moz-linear-gradient( top,
                              rgba(255, 255, 255, .25),
                              rgba(0,0,0,.2)),
       -moz-linear-gradient( left, #09c, #690);
}
  
  /* Now we are good to duplicate html code for other skills and then add the css code for the new skill based on data-skill */
  
    
  /* THE END */

.skill-tec > .right > span.-fav {
    color: #11e8ff;
}

.main-title.--java_core_skills {
    margin-top: 2rem;
}

.skill-tec > .right > span.-OldStudy {
    color: red;
    background-color: #fff;
    text-shadow: none;
}

  @media (max-width: 810px)
{
    .wrapper-skills {
        max-width: fit-content;
        margin: .5rem;

    }
    .icon {
        max-width: 4rem;
        max-height: 4rem;
    }

    .right {
        font-size: .8rem;
    }

    .title-skill {
        font-size: 0.65rem;
    }
    
    .skill-tec div progress {
        width: 6rem;
        height: .8rem;
    }

    .skill-tec > .right > span{
        font-size: .7rem;
    }

    progress.progress-bar.--skills[value] {
        width: 6rem;
        height: .8rem;
    }
    
}

@media (max-width: 425px)
{
    #main-skills--container .main-title {
        margin-top: 3rem;
    }
    .icon {
        max-width: 3.1rem;
        max-height: 3.1rem;
    }

    .right {
        font-size: .7rem;
        margin: 0 0 0 .3rem;
    }

    .title-skill {
        font-size: 0.54rem;
    }
    
    
    .skill-tec div progress {
        width: 5rem;
        height: .6rem;
    }
    .skill-tec > .right > span{
        font-size: .5rem;
        margin: 0 0 3px 0;
    }

    progress.progress-bar.--skills[value] {
        width: 5rem;
        height: .6rem;
    }

    .wrapper-skills {
        max-width: none;
        margin: .2rem;
    }
}