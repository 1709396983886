.main-header--container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-header--left {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
}

.main-header--left > h1 {
    color: white;
    font-size: 2rem;
    font-weight: lighter;
    font-family: 'Press Start 2P', cursive;
    text-align: center;
    text-shadow: 0px 0px 10px #f00;
}

.main-header--left > h1.--home-especializando {
    font-size: 1.3rem;
    color: #f00;
    text-shadow: none;
    min-width: max-content;
}

.main-header--right {
    flex-grow: 1;
    height: 100%;
    text-align: end;
    margin-top: 10vh;
    width: 0vw;
}

.main-header--right img{
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    width: 100%;
}

img.--perfil-InMobile {
    display: none;
}

/* Rubrica com fonte personalizada */

/* Obs: Não coloca em variaveis no index.js pois só é utilizado aqui essa fonte. */

@font-face {
    font-family: 'Rubrica Upper Case Font Family';
    src: url('./../../resources/fonts/Jonathan Hetegral.otf');
}

.main-header--container > .main-header--left > h1.--home-rubrica {
    font-family: 'Rubrica Upper Case Font Family';
    font-size: 3.5rem;
    line-height: 3.5rem;
    color: #fff;

    text-shadow: none;

    margin-bottom: -3.5rem;
}

.moldura-dois {
	border:8px inset #5f6632;
	padding:15px;
	background: #b1b1c3;
	}

.moldura-um {
	border:20px inset #5d738b;
	padding:25px;
	background: transparent url('../../resources/images/home/moldura_azul_granulado_bg-um.gif');
	}

@media (max-width: 810px)
{
    
    .main-header--container {
        flex-direction: column;
        align-items: center;
        justify-content: end;
    }

    .main-header--left {
        order: 2;
        flex-grow: 3;
    } 
    
    .main-header--right {
        order: 1;
        width: 100vw;
        text-align: center;
    }
    
    section {
        top: 0;
    }

    .main-header--left {
        padding: 1rem 0;
    }

    .main-header--left > h1 {
        font-size: 1.75rem;
    }
   
    .main-header--left > h1.--home-especializando {
        min-width: fit-content;
        font-size: 1.15rem;
    }  

    .main-header--container > .main-header--left > h1.--home-rubrica {
        font-size: 3.25rem;
        margin-bottom: 0px;
    }
}

@media (max-width: 425px)
{
    .main-header--right {
        order: 1;
        width: 100%;
        margin-top: 3rem;
    }

    .main-header--left > h1 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .main-header--left > h1.--home-especializando {
        font-size: .8rem;
        line-height: .9rem;
    }  

    section {
        top: 0;
    }

    img.--perfil-InMobile { 
        display: inherit;
    }

    img.--perfil-InDesktop { 
        display: none;
    }


    .main-header--left {
        padding: 1rem 0;
    }

    .main-header--container > .main-header--left > h1.--home-rubrica {
        margin-bottom: 0px;
        font-size: 2.25rem;
        line-height: 2.75rem;
    }

    .main-header--left {
        padding: 1rem 0;
    }
    
}