.video-carrinho-arduino-bluetooth--container {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
}

.video-carrinho-arduino-bluetooth-wrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (adjust as needed) */
}

.video-carrinho-arduino-bluetooth {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px ridge #000;
}

.video-carrinho-arduino-bluetooth--container--hidden {
    display: none;
}

.video-carrinho-arduino-bluetooth--hidden {
    width: 100%;
    border: 4px ridge #000;
}

p.main-title--implementFullBlock {
    margin-top: .5rem;
    margin-left: .5rem;
    display: list-item;
    list-style: disc;
    list-style-position: inside;
    color: wheat;
}

p.main-title--implementFullBlock::marker {
    color: red;
}

ul.main-implementFullBlock--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: .5rem 0 0 .5rem;
}

code.implementFullBlock {
    background-color: #200a1c;
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
    color: #fff;
    font-weight: bolder;
    border-radius: 5px;
    padding: .5rem;
    font-size: .9rem;
    text-shadow: 1px 1px 3px #000;
    line-height: 1rem;
    text-align: left;
    width: 100%;
    border: 3px double #000;
}

span.-tokenComment {
    color: #00d000;
    font-weight: 400;
}

code.-tokenMethod {
    color: #d2a8ff;
    font-size: .8rem;
}

code.-tokenString {
    color: #a5d6ff;
    font-size: .8rem;
}

code.-tokenKeyConstant {
    color: #79c0ff;
    font-size: .8rem;
}

.-nestedInnerCode {
    margin: 0 0 0 1rem;
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
}

.-nestedInnerCode.--2Identation {
    margin: 0 0 0 2rem;
}

ul.projects-frontend--container > li.-marginNone--inMobile > p.-listItem--inMobile.--newThemeBWMCiano::marker{
    color: rgb(5, 207, 207);
}


@media (max-width: 810px)
{
    ul.main-implementFullBlock--container {
        margin: 0.5rem 0 0 0;
    }
}

@media (max-width: 425px)
{   
    .video-carrinho-arduino-bluetooth--container--hidden {
        display: flex;
        flex-direction: column;
    }

    .video-carrinho-arduino-bluetooth--container {
        display: none;
    }

    p.main-title--implementFullBlock {
        margin-left: 0rem;
    }

    code.implementFullBlock {
        font-size: .6rem;
        text-shadow: 1px 1px 3px #000;
        line-height: 1rem;
        padding: 0.2rem 0 0.2rem 0.2rem;
    }

    span.-tokenComment {
        font-size: .6rem;
    }

    code.-tokenMethod {
        font-size: .6rem;
    }

    code.-tokenString {
        font-size: .6rem;
    }

    code.-tokenKeyConstant {
        font-size: .6rem;
    }
}