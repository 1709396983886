#main-sobre--container {
    width: 100%;
}

#main-sobre--container > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#main-sobre--container > div > div {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

#main-sobre--container > div p{
    font-size: 1rem;
    font-family: var(--main-fontFamily);
    color: var(--main-textColor);
    text-shadow: 1px 1px 2px black;
    background-color: rgb(32, 10, 28);
    text-align: justify;
    padding: .5rem;
    box-shadow: 2px 2px 3px black;
    line-height: 1.3rem;
}

#main-sobre--container > div p span{
    font-size: .9rem;
}

/* #main-sobre--container div ul {
    max-width: max-content;
    font-size: 1rem;
    font-family: var(--main-fontFamily);
    color: white;
    text-shadow: 0.05rem 0.05rem 0.00009rem black;
    list-style-position: inside;
    padding: .5rem;
} */

.main-sobre--shellHead {
    display: flex;
    flex-direction: row;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: #262626;
    padding: .5rem;
    color: white;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 3px black;
}

.main-sobre--shellHead i > svg{
    font-size: 2rem;
    max-width: fit-content;
    background-color: #404040;
    height: 2rem;
    border-radius: 5px;
    padding: .3rem;
    color: white;
    margin-right: .5rem;
    border: 1px solid #1d1d1d;
}

.main-sobre--shellHeadWindowCommomIcons > i > svg{
    border-radius: 50%;
}

.main-sobre--shellHead div {
    max-width: max-content;
    display: flex;
    align-items: center;
}

.main-sobre--shellHead > span > h3{
    max-width: fit-content;
    font-weight: normal;
    font-size: .8rem;
    font-family: var(--main-fontFamily-secondTitle);
}

.main-sobre--shellHeadWindowCommomIcons > i > svg {
    font-size: 1.5rem;
    height: 1.5rem;
}


.main-sobre--shellDir, .main-sobre--shellUser  {
    color: #11e8ff;
    font-family: var(--main-fontFamily-secondTitle);
    font-weight: normal;
}

@media (max-width: 810px)
{
    #main-sobre--container > div {
        justify-content: flex-start;
    }

    #main-sobre--container > div > div {
        padding: 0;
    }

    #main-sobre--container div p{
        max-width: max-content;
    }

    /* #main-sobre--container div ul {
        max-width: max-content;
        margin: .5rem;
        padding: 0;
    } */

    .main-sobre--shellHead > span {
        margin-left: 0;
    }
    .main-sobre--shellHead > span > h3{
        font-size: .75rem;
    }

    #main-sobre--container > div p span{
        font-size: .875rem;
    }
    
}

@media (max-width: 425px)
{
    #main-sobre--container .main-title {
        margin-top: 3rem;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }

    #main-sobre--container div p{
        max-width: max-content;
    }

    /* #main-sobre--container div ul {
        margin: 1rem 0 0 0;
        font-size: .9rem;
    } */

    #main-sobre--container > div {
        justify-content: flex-start;
    }

    .main-sobre--shellHead {
        margin: 1rem 0 0 0;
    }

    .main-sobre--shellHead > span{
        display: none;
    }

    #main-sobre--container > div p span{
        font-size: .85rem;
    }
}