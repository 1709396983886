code.token_reservada {
    background-color: var(--main-bgColorLightGrey);
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
    color: #000;
    font-weight: bolder;
    padding: 0 1px;
    font-size: .8rem;
    text-shadow: 1px 1px 2px white;
    line-height: 1rem;
}

ul.-nestedInnerUl {
    margin: .5rem 0 0 .5rem;
    list-style-type: disc;
}

ul.-nestedInnerUl li::marker {
    color: red;
}

li code.-main-moduleTitleLi {
    font-size: .9rem;
}

code.outputResult {
    background-color: #200a1c;
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
    color: #fff;
    font-weight: bolder;
    border-radius: 5px;
    padding: 0 2px;
    font-size: .8rem;
    text-shadow: 1px 1px 3px #000;
}

.-nestedInnerCode {
    margin: 0 0 0 1rem;
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
}

.-nestedInnerCode.--2Identation {
    margin: 0 0 0 2rem;
}

.-nestedInnerCode.--3Identation {
    margin: 0 0 0 3rem;
}

.-nestedInnerCode.--4Identation {
    margin: 0 0 0 4rem;
}

.-nestedInnerCode.--5Identation {
    margin: 0 0 0 5rem;
}

code.-tokenMethod {
    color: #d2a8ff;
    font-size: .8rem;
}

code.-tokenString {
    color: #a5d6ff;
    font-size: .8rem;
}

code.-tokenKeyword {
    color: #ff7b72;
    font-size: .8rem;
}

code.-tokenKeyConstant {
    color: #79c0ff;
    font-size: .8rem;
}

code.-tokenClassEntity {
    color: #cdcdcd;
    font-size: .8rem;
}

span.-tokenComment {
    color: #00d000;
    font-weight: 400;
}

code.-tokenInterfaceEntity {
    color: #6ebc6e;
    font-size: .8rem;
}

code.-tokenAnnotationsEntity {
    color: #d7d01c;
    font-size: .8rem;
}

ul.main-implementFullBlock--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: .5rem 0 0 .5rem;
}

code.implementFullBlock {
    background-color: #200a1c;
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
    color: #fff;
    font-weight: bolder;
    border-radius: 5px;
    padding: .5rem;
    font-size: .9rem;
    text-shadow: 1px 1px 3px #000;
    line-height: 1rem;
    text-align: left;
    width: 100%;
    border: 3px double #000;
}

p.main-title--implementFullBlock {
    margin-top: .5rem;
    margin-left: .5rem;
    display: list-item;
    list-style: disc;
    list-style-position: inside;
    color: wheat;
}

p.main-title--implementFullBlock::marker {
    color: red;
}

span.outputResult--implementFullBlock {
    background-color: black;
    color: #11e8ff;
    font-weight: bolder;
    display: block;
    text-align: center;
    border-radius: 4px;
    border: 3px double #044910;
    padding: 2px;
}

ul.projects-frontend--container > li.-marginNone--inMobile > p {
    margin-left: .5rem;
    display: list-item;
    list-style-type: square;
}

ul.projects-frontend--container > li.-marginNone--inMobile > p:nth-child(even) {
    list-style-type: disc;
}

ul.projects-frontend--container > li.-marginNone--inMobile > p.-listItem--inMobile {
    margin-left: 0;
    margin-right: 0;
}

ul.projects-frontend--container > li.-marginNone--inMobile > p.-listItem--inMobile::marker {
    color: #1877ff;
    margin-right: 0;
}

ul.projects-frontend--container > li.-marginNone--inMobile {
    list-style-type: none;
}

pre.pre-implementFullBlock {
    display: inline-block;
    font-size: .8rem;
}

.--fontSize1rem {
    font-size: 1rem !important;
}

.--fontSize1Dot5rem {
    font-size: 1.5rem !important;
}

ul.javaBible-listOfTitle--container {
    font-family: var(--main-fontFamily);
    color: #7b83da;
    font-weight: 500;
    font-size: .8rem;
    list-style-position: inside;
    line-height: 1rem;
    text-shadow: 1px 1px 3px black;
    list-style-type: square;
}

ul.javaBible-listOfTitle--container li::marker {
    color: white;
}
 
@media (max-width: 810px)
{
    code.token_reservada {
        font-size: .7rem;
    }

    ul.projects-description--container {
        margin: 0;
    }

    ul.projects-description--container > li > h3 {
        margin-left: .5rem;
        margin-right: .5rem;
    }

    ul.projects-frontend--container > li {
        margin-left: .5rem;
        margin-right: .5rem;
    }
    
    ul.projects-frontend--container > li.-marginNone--inMobile {
        margin-left: 0;
        margin-right: 0;
    }

    ul.projects-frontend--container > li.-marginNone--inMobile > p {
        margin-left: .5rem;
        margin-right: .5rem;
    }

    ul.projects-frontend--container > li.-marginNone--inMobile > p.-listItem--inMobile {
        margin-left: .5rem;
        margin-right: .5rem;
    }

    ul.main-implementFullBlock--container {
        margin: 0.5rem 0 0 0;
    }
}

@media (max-width: 425px)
{
    code.token_reservada {
        font-size: .6rem;
        padding: 0 1.5px;
    }

    ul.-nestedInnerUl {
        margin: 0.5rem 0;
    }
    
    li code.-main-moduleTitleLi {
        font-size: .6rem;
    }

    /* Área da caixa de exemplo de códigos */

    code.-tokenMethod {
        font-size: .6rem;
    }
    
    code.-tokenString {
        font-size: .6rem;
    }
    
    code.-tokenKeyword {
        font-size: .6rem;
    }
    
    code.implementFullBlock {
        font-size: .6rem;
        text-shadow: 1px 1px 3px #000;
        line-height: 1rem;
        padding: 0.2rem 0 0.2rem 0.2rem;
    }

    code.outputResult {
        font-size: .6rem;
    }

    code.-tokenKeyConstant {
        font-size: .6rem;
    }
    
    code.-tokenClassEntity {
        font-size: .6rem;
    }

    span.-tokenComment {
        font-size: .6rem;
    }

    code.-tokenInterfaceEntity {
        font-size: .6rem;
    }

    code.-tokenAnnotationsEntity {
        font-size: .6rem;
    }

    p.main-title--implementFullBlock {
        margin-left: 0rem;
    }

    pre.pre-implementFullBlock {
        font-size: .6rem;
    }
}