#template-preview-full {
    font-weight: 600;
}

article, aside, footer, header, nav, section {
    display: block;
}

.visible-preview {
    width: 100%
}

.sc-15b5x2p-1.gOzbkZ {
    padding-top: 5rem;
    padding-bottom: .8rem;
}

tr .template__professional__column, .template__newyork__main.template__professional__main span, .template__nameOccupation > .template__occupation {
    color: black;
}

.template__newyork .template__section__college, .template__section__right .template__section__company, .template__nameOccupation > .template__occupation > p{
    color: #11e8ff;
    font-family: var(--main-fontFamily-secondTitle) !important;
    font-size: .85rem;

}

.template__header__title {
    height: max-content;
}

.tableCurriculoVitae
{
    max-width: 1200px;
}

.template__header.template__header--twoColumns, #education .template__section__right .template__section__description
{
    text-align: justify;
}

.template__header__info > p:nth-child(1){
    color: #f00;
    font-family: var(--main-fontFamily-secondTitle);
    font-size: .85rem;
}

.template__professional__column.template__newyork__column > section > .template__section__title {
    text-align: center;
}

#skills > .template__section__title, #languages > .template__section__title {
    padding-top: .9rem;
}

.gOzbkZ {
position: relative;
z-index: 1;
}

.esahFp .template.html {
background: white none repeat scroll 0% 0%;
border: medium none;
transform-origin: 0px 0px 0px;
min-height: 842px;
box-shadow: rgb(217, 221, 224) 0px 0px 34px 0px;
}

.khUhxh.paginatorItem {
display: flex;
box-sizing: border-box;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
position: absolute;
top: 1095px;
left: 5px;
padding: 10px;
font-size: 12px;
height: 30px;
background-color: rgb(34, 152, 233);
transform: translate(-100%, -50%);
border-radius: 3px 0px 0px 3px;
text-transform: uppercase;
font-family: var(--main-fontFamily);
letter-spacing: 3px;
font-weight: bold;
color: rgb(255, 255, 255);
border: medium none;
}

.color--blue .template__newyork__column {
background-color: var(--main-bgColorLightGrey);
border: 4px double black;
font-family: var(--main-fontfamily-Press2PLight);
}

.template__newyork__column {
background: #D0021B;
background-color: rgb(208, 2, 27);
padding: 40px 16px 50px 50px;
color: #58e70e;
}


.template__professional__column {
display: table-cell;
width: 360px;
vertical-align: top;
}
.template__newyork__column .template__section__title {
border-bottom: 1px solid black;
}
.template__newyork .template__section__title, .template__newyork .template__section__company, .template__newyork .template__section__college {
font-weight: 700;
}
.template__professional__column .template__section__title {
padding-bottom: 8px;
}
.template__section__title {
page-break-after: avoid;
}
.template__professional .template__section__title {
font-size: 15px;
margin-bottom: 16px;
color: #f00;
font-family: var(--main-fontFamily-secondTitle);
}
.template__section__subtitle, .template__section__position, .template__section__degree, .template__occupation {
font-weight: 700;
font-size: 1rem;
}
.template__newyork .template__section__info__value {
word-break: break-word;
word-wrap: break-word;
text-shadow: 1px 1px 2px rgb(5, 207, 207);
}

.template__section__languages.template__section__simple {
    text-shadow: 1px 1px 2px rgb(5, 207, 207);
}

.template__professional .template__header__info {
margin-bottom: 6px;
}

.template__newyork__main {
padding: 40px 50px 50px 0;
}

.template__professional__main {
display: table-cell;
vertical-align: top;
text-shadow: 1px 1px 2px black;
}

.template__header--twoColumns, .template__section--twoColumns {
display: flex;
}
.template__header--twoColumns {
justify-content: space-between;
}
.template__professional .template__header, .template__professional .template__section__experience:last-child, .template__professional .template__section__education:last-child, .template__professional .template__section__custom:not(:last-child) {
border-bottom: 1px solid var(--main-bgColorLightGrey);
}

.template__professional .template__header {
padding-bottom: 16px;
margin-bottom: 16px;
}
.template__newyork .template__name {
text-transform: capitalize;
}
.template__professional .template__name {
font-weight: 700;
}
.template__name span:first-child {
margin-right: .4em;
}
.template__professional .template__name {
text-transform: uppercase;
font-size: 18px;
line-height: 17px;
}

.template__professional .template__name > span {
    color: white !important;
    font-family: var(--main-fontFamily-secondTitle);
}

.template__professional .template__header__column {
min-width: calc(100% - 75px - 16px);
max-width: 100%;
}
.template__occupation {
font-size: 16.5px;
margin-bottom: 5px;
}
.line-spacing--100.template__newyork .template__section__simple:not(.template__section__languages):not(.template__section__simple--fixed-line-spacing), .line-spacing--100.template__newyork .template__section__description:not(.template__section__languages):not(.template__section__simple--fixed-line-spacing) {
line-height: 20px;
}
.font-size--medium.template__newyork .template__section__simple:not(.template__section__simple--fixed-font-size):not(.template__section__languages), .font-size--medium.template__newyork .template__section__description:not(.template__section__simple--fixed-font-size):not(.template__section__languages) {
font-size: 15.5px;
color: var(--main-textColor);
font-weight: 400;
padding-right: .5rem;
}
.template__newyork__main .template__header .template__section__simple {
padding-left: 0;
}
.template__newyork__main .template__section__simple {
padding-left: 45px;
}

.template__header .template__section__simple {
margin-bottom: 0;
padding-bottom: 0;
border-bottom: 0;
padding-left: 0;
}

.template__professional .template__section__experience:last-child, .template__professional .template__section__education:last-child {
margin-bottom: 16px;
}

.template__professional .template__section__experience, .template__professional .template__section__education {
min-height: 30px;
}

.template__professional .template__header, .template__professional .template__section__experience:last-child, .template__professional .template__section__education:last-child, .template__professional .template__section__custom:not(:last-child) {
border-bottom: 1px solid var(--main-bgColorLightGrey);
}

.template__professional .template__section__left {
position: relative;
width: 172px;
padding-right: 16px;
padding-bottom: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
}
.template__professional .template__section__left {
border-right: 1px solid var(--main-bgColorLightGrey);
height: auto;
}
.color--blue.template__newyork .template__section__left::before {
color: var(--main-bgColorLightGrey);
background-color: var(--main-bgColorLightGrey);
}
.template__newyork .template__section__left::before {
font-family: 'Icons Templates';
position: absolute;
content: '.';
font-size: 12px;
line-height: 8px;
color: #D0021B;
top: -4px;
right: -5.5px;
width: 8px;
height: 10px;
}
.template__professional .template__section__startDate, .template__professional .template__section__endDate, .template__professional .template__section__location {
font-size: 13.5px;
font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
}
.template__professional .template__section__startDate, .template__professional .template__section__endDate {
font-style: italic;
}
.template__professional .template__section__startDate, .template__professional .template__section__endDate, .template__professional .template__section__location {
font-size: 13.5px;
font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
}
.template__section__experience .template__section__startDate, .template__section__experience .template__section__endDate, .template__section__education .template__section__startDate, .template__section__education .template__section__endDate {
display: inline;
}
.template__section__company, .template__section__college, .template__section__experience .template__section__startDate, .template__section__experience .template__section__endDate, .template__section__education .template__section__startDate, .template__section__education .template__section__endDate {
font-style: italic;
margin-top: 3px;
}
.template__section__experience .template__section__endDate::before, .template__section__education .template__section__endDate::before {
content: " Até ";
font-size: .8rem;
}
.template__professional .template__section__right {
width: calc(100% - 172px);
padding-left: 16px;
margin-top: -10px;
}

.color--blue.template__newyork .template__section__degree, .color--blue.template__newyork .template__section__position {
color: white;
font-family: var(--main-fontFamily-secondTitle);
font-size: 15px;
}
.template__newyork .template__section__position, .template__newyork .template__section__degree {
font-size: 16px;
color: #D0021B;
}
.template__newyork .template__section__company, .template__newyork .template__section__college {
font-style: normal;
}
.line-spacing--100.template__newyork .template__section__simple:not(.template__section__languages):not(.template__section__simple--fixed-line-spacing), .line-spacing--100.template__newyork .template__section__description:not(.template__section__languages):not(.template__section__simple--fixed-line-spacing) {
line-height: 20px;
}
.font-size--medium.template__newyork .template__section__simple:not(.template__section__simple--fixed-font-size):not(.template__section__languages), .font-size--medium.template__newyork .template__section__description:not(.template__section__simple--fixed-font-size):not(.template__section__languages) {
font-size: 15.5px;
color: var(--main-textColor);
font-weight: 400;
padding-right: .5rem;
}

.template__section__description {
margin-top: 8px;
}
.template__section__description div {
margin-bottom: 8px;
}

.template * {
box-sizing: border-box;

}
.font-family--professional.template__newyork {

font-family: var(--main-fontFamily);

}
.template__newyork {

color: white;
font-family: var(--main-fontFamily);

}
.template__professional {

font-size: 15.5px;
line-height: 1.5em;
border-spacing: 0;
word-wrap: break-word;

}

.font-family--professional.template__newyork {
font-family: var(--main-fontFamily);
}

.template__professional .template__header {
padding-bottom: 16px;
margin-bottom: 16px;
}

.template__professional .template__header, .template__professional .template__section__experience:last-child, .template__professional .template__section__education:last-child, .template__professional .template__section__custom:not(:last-child) {
border-bottom: 1px solid var(--main-bgColorLightGrey);
}

.template * {
margin: 0;
margin-bottom: 0px;
padding: 0;
padding-bottom: 0px;
box-sizing: border-box;
}

.template__header--twoColumns {
justify-content: space-between;
}

.template__header--twoColumns, .template__section--twoColumns {
display: flex;
}

.font-family--professional.template__newyork {
font-family: var(--main-fontFamily);
}


.template__newyork {
color: #11e8ff;
font-family: "Bitter",sans-serif;
}

.template__section__title.-mainBody > span {
    color: white !important;
}

.esahFp .template.html {
background: white none repeat scroll 0% 0%;
border: medium none;
transform-origin: 0px 0px 0px;
min-height: 842px;
box-shadow: rgb(217, 221, 224) 0px 0px 34px 0px;
}

.padding-1rem {
    padding: .5rem .5rem .5rem .5rem;
}

.paddingOfSkillsUl-1rem {
    padding: .5rem 0 .5rem .5rem;
}

.template__section__right .template__section__description:last-child {
    margin-bottom: .5rem;
}

section#education > div.template__section__container > div.template__section.template__section__education.template__section--twoColumns {
    border-bottom: 1px solid var(--main-bgColorLightGrey);
}

.template__section.template__section__education.template__section--twoColumns.--lastOfsuperiorADS {
    border-bottom: 1px solid var(--main-bgColorLightGrey);
}

section.diferenceBeetweenGradeAndMatrizCurricular {
    text-align: justify;
}

section.diferenceBeetweenGradeAndMatrizCurricular > .template__section__description.--question {
    color: #fff !important;
}

.color-font-white-highlighted {
    color: white !important;
}

.-marginBottomNone {
    margin-bottom: 0px !important;
}

#skills > div.template__section__container > ul > li > a {
	background-color: #9b9bff;
	line-height: 1rem;
	max-width: fit-content;
	margin-bottom: .5rem;
    text-shadow: 1px 1px 2px rgb(5, 207, 207);
    color:#000;
    padding: 0 2px;
    transition: all ease 0.3s;
}

#skills > div.template__section__container > ul > li > a:hover {
    background-color: #000;
    color:#11e8ff;
    text-shadow: none;
}

.template__section__coordinator {
    height: unset;
    width: unset;
}

.template__section__coordinato_position  {
    color: white;
    font-family: var(--main-fontFamily-secondTitle);
    font-size: 12.5px;
}

.template__section__coordinator_name, .template__section__coordinator_email, .template__section__coordinator_phone {
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    font-style: italic;
    font-size: 12.5px;
}


@font-face {
    font-family: 'Top Secret Stamp Font Family';
    src: url('./../../resources/fonts/Top Secret Stamp.ttf');
}


.template__section__description.color-font-white-highlighted.--dangerAttention-with-top-secret-stamp-font-family {
    color: red !important;
    font-weight: bold;
    font-family: 'Top Secret Stamp Font Family';
    font-size: 1.5rem !important;
}

.template__section__description.color-font-white-highlighted > code.--flag-string-for-search {
    font-weight: bold;
    color: #000;
    text-shadow: 1px 1px 2px rgb(5, 207, 207);
    font-size: .8rem;
    background-color: #fff;
    padding: 0 1px;
    margin: 2px auto 0 1rem;
    text-align: center;
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
}

.template__section__description.color-font-white-highlighted > code.-efeitoMarcaTexto {
    padding: 0 2px;
    font-size: 1rem;
}


@media (max-width: 810px)
{
    #tdEspaco{
        display: none;
    }
    .tableCurriculoVitae {
        padding: .5rem .5rem 0 .5rem;
    }
    
    .template__header.template__header--twoColumns, #education .template__section__right .template__section__descriptio {
        padding-left: .5rem;
    }

    .sc-15b5x2p-1.gOzbkZ {
        padding-top: 4rem;
    }

    .template__professional .template__section__right {
        width: 100%;
    }

    .template__professional__column.template__newyork__column {
        display: none;
    }

    .template__professional .template__section__right {
        border-left: 1px solid var(--main-bgColorLightGrey);
    }

    .template__nameOccupation {
        text-align: center;
    }

    .template__professional .template__section__left {
        height: auto;
        border-right: none;
        display: none;
    }

    .template__section__title {
        text-align: center;
    }

    /* .template__professional .template__section__right {
        margin: 0;
    } */

    .template__section__position::before {
        background-color: var(--main-bgColorLightGrey);;
        color: white;
        font-size: 12px;
        content: '.';
        font-family: 'Icons Templates';
        position: absolute;
        content: '.';
        line-height: 4px;
        left: -3px;
        width: 8px;
        height: 10px;
    } 

    .template__section__degree::before {
        background-color: var(--main-bgColorLightGrey);;
        color: white;
        font-size: 12px;
        content: '.';
        font-family: 'Icons Templates';
        position: absolute;
        content: '.';
        line-height: 4px;
        left: -3px;
        width: 8px;
        height: 10px;
    }

    .template__newyork .template__section__left::before {
        display: none;
    }

    .template__section__simple--border > .template__section__container {
        padding-left: 1rem;
    }

    .template__newyork__main .template__section__simple {
        padding-left: 0;
    }

    .template__section__description div {
        margin-bottom: 0;
    }

    .template__professional .template__section__right {
        margin-bottom: 0;
        padding-bottom: .5rem;
        margin-top: 0px;
    }

    #education span.-spanFormacaoAcad--superior::after { /* -spanFormacaoAcad--tecnica */
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: 1rem;
        font-size: 12px;
        padding-top: .5rem;
        border-top: 3px double #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    #education .template__section__title.-spanFormacaoAcad--superior::after {
        content: 'Fev 2023 Até Atualmente';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: .8rem;
        font-size: 12px;
        padding: 0 .5rem;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    /* #education span.-spanFormacaoAcad--superior.--gradeCurricularCurrentLastSemestre::after {
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        line-height: 1rem;
        font-size: 12px;
        padding-top: .5rem;
        border-top: 3px double #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    #education .template__section__title.-spanFormacaoAcad--superior.--gradeCurricularCurrentLastSemestre::after {
        content: 'Ago 2023 Até Dez 2023';
        position: relative;
        display: block;
        color: #000;
        line-height: .8rem;
        font-size: 12px;
        padding: 0 .5rem;
        text-shadow: none;
    }


    #education span.-spanFormacaoAcad--superior.--gradeCurricular2SemestreEmDiante::after {
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        line-height: 1rem;
        font-size: 12px;
        padding-top: 0;
        border-top: none;
    }

    #education .template__section__title.-spanFormacaoAcad--superior.--gradeCurricular1Semestre::after {
        content: 'Fev 2023 Até Jul 2023';
    }

    #education .template__section__title.-spanFormacaoAcad--superior.--gradeCurricular3Semestre::after {
        content: 'Fev 2024 Até Jul 2024';
    }

    #education .template__section__title.-spanFormacaoAcad--superior.--gradeCurricular4Semestre::after {
        content: 'Ago 2024 Até Dez 2024';
    } */

    #education span.-spanFormacaoAcad--tecnico::after { /* -spanFormacaoAcad--tecnica */
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: 1rem;
        font-size: 12px;
        padding-top: .5rem;
        border-top: 3px double #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    #education .template__section__title.-spanFormacaoAcad--tecnico::after {
        content: 'Fev 2021 Até Dez 2022';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: .8rem;
        font-size: 12px;
        padding: 0 .5rem;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    /* #education span.-spanFormacaoAcad--tecnico.--gradeCurricular4Semestre::after {
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        line-height: 1rem;
        font-size: 12px;
        padding-top: .5rem;
        border-top: 3px double #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    #education .template__section__title.-spanFormacaoAcad--tecnico.--gradeCurricular4Semestre::after {
        content: 'Jul 2022 Até Dez 2022';
        position: relative;
        display: block;
        color: #000;
        line-height: .8rem;
        font-size: 12px;
        padding: 0 .5rem;
        text-shadow: none;
    }


    #education span.-spanFormacaoAcad--tecnico.--gradeCurricular3SemestreEmDiante::after {
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        line-height: 1rem;
        font-size: 12px;
        padding-top: 0;
        border-top: none;
    }

    #education .template__section__title.-spanFormacaoAcad--tecnico.--gradeCurricular1Semestre::after {
        content: 'Fev 2021 Até Jun 2021';
    }

    #education .template__section__title.-spanFormacaoAcad--tecnico.--gradeCurricular2Semestre::after {
        content: 'Jul 2021 Até Dez 2021';
    }

    #education .template__section__title.-spanFormacaoAcad--tecnico.--gradeCurricular3Semestre::after {
        content: 'Fev 2022 Até Jun 2022';
    } */
    

    
    
    .template__section__title {
        width: max-content;
        margin: 0 auto;
        border: 3px double #000;
        border-radius: 5px;
        padding: .5rem 0;
        margin-bottom: .9rem;
        background-color: var(--main-bgColorLightGrey);
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    #work-experience span::after {
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: 1rem;
        font-size: 12px;
        padding-top: .5rem;
        border-top: 3px double #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    #work-experience .template__section__title::after {
        content: 'Nov 2018 Até Nov 2020';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: .8rem;
        font-size: 12px;
        padding: 0 .5rem;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    #volunteer-work span::after {
        content: 'Salto - SP, Brasil';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: 1rem;
        font-size: 12px;
        padding-top: .5rem;
        border-top: 3px double #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    #volunteer-work .template__section__title::after {
        content: 'Mai 2022 Até Jun 2023';
        position: relative;
        display: block;
        color: #000;
        text-shadow: 1px 1px 2px #05cfcf;
        line-height: .8rem;
        font-size: 12px;
        padding: 0 .5rem;
        font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace !important;
    }

    .template__section__title.-mainBody > span {
        color: #000 !important;
        text-shadow: 1px 1px 2px #05cfcf;
        font-family: var(--main-fontFamily-secondTitle);
        font-size: .75rem;

    }

    section.diferenceBeetweenGradeAndMatrizCurricular {
        padding-left: .5rem;
    }

    .-text-align-center-on-mobile {
        text-align: center;
    }

    .template__professional .template__name > span {
        font-size: .9rem;
    }

    .template__newyork .template__section__college, .template__section__right .template__section__company, .template__nameOccupation > .template__occupation > p {
        font-size: .8rem !important;
    }


    .template__section__description.color-font-white-highlighted.--dangerAttention-with-top-secret-stamp-font-family {
        font-size: 1.25rem !important;
    }
    
    .template__section__description.color-font-white-highlighted > code.--flag-string-for-search {
        font-size: .7rem;
        padding: 0 2px;
    }
    
    .template__section__description.color-font-white-highlighted > code.-efeitoMarcaTexto {
        font-size: 0.9rem;
    }
    
}

@media (max-width: 425px)
{

    .template__professional .template__section__title {
        font-size: 14px; 
        margin-bottom: 10px;
    }

    .template__professional .template__name {
        font-size: 16px; 
        line-height: 13px;
        margin-bottom: 2px;
    }

    .template__occupation {
        font-size: 13.5px; 
        margin-bottom: 2px;
    }

    .font-size--medium.template__newyork .template__section__simple:not(.template__section__simple--fixed-font-size):not(.template__section__languages), .font-size--medium.template__newyork .template__section__description:not(.template__section__simple--fixed-font-size):not(.template__section__languages) {
        font-size: 13.5px;
    }

    .template__newyork .template__section__left::before {
         font-size: 10px; 
    }

    .template__professional .template__section__startDate, .template__professional .template__section__endDate, .template__professional .template__section__location {
        font-size: 12.5px; 
    }

    .template__newyork .template__section__position, .template__newyork .template__section__degree {
        font-size: 14px; 
    }

    .font-size--medium.template__newyork .template__section__simple:not(.template__section__simple--fixed-font-size):not(.template__section__languages), .font-size--medium.template__newyork .template__section__description:not(.template__section__simple--fixed-font-size):not(.template__section__languages) {
        font-size: 13.5px;
        line-height: 1.1rem;
    }

    .template__professional {
        font-size: 12.5px;
        line-height: 1rem; 
    }

    .template__section__position {
        line-height: .9rem;
    }

    .template__section__degree {
        line-height: .9rem;
    }

    .template__professional .template__name > span {
        font-size: .85rem;
    }

    .template__nameOccupation > .template__occupation > p {
        font-size: .7rem !important;
    }

    .template__newyork .template__section__college, .template__section__right .template__section__company {
        font-size: .65rem !important;
    }

    .color--blue.template__newyork .template__section__degree, .color--blue.template__newyork .template__section__position {
        font-size: .7rem !important;
    }

    .template__section__description.color-font-white-highlighted > code.-efeitoMarcaTexto {
        font-size: 0.7rem !important;
    }
}