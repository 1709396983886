#main-projects--container {
    width: 100%;
}

.projects-single {
    display: flex;
    gap: .2rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.projects-logo {
    border: 4px ridge #000;
    height: 100%;
    width: 100%;
    margin-bottom: -4px !important;
}

.projects-right {
    border-radius: 10px;
    /* background: linear-gradient(90deg,#fff 0,6%,#2f9919,#267b15); antigamente, ruim pois força vista*/ 
    --tw-bg-opacity: .5;
    background-color: rgba(52,53,65,var(--tw-bg-opacity));

    max-width: 80%;
    border-left: 2px ridge lightgrey;
    border-right: 2px ridge lightgrey;

}

.projects-title--container {
    background-color: #0d1117;
    border: 2px ridge lightgrey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 .5rem .5rem .5rem;
    cursor: default;
}

.projects-title--container > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.projects-title--container > span {
    font-weight: normal;
    color: #000;
    text-shadow: 1px 1px 2px rgb(5, 207, 207);
    font-size: .8rem;
    background-color: var(--main-bgColorLightGrey);
    padding: 0 1px;
    margin: 2px auto 0 auto;
    font-family: var(--main-fontFamily-secondTitle) !important;
    text-align: center;
}

.projects-title {
    padding: 1rem 0 .6rem 2rem;
    font-size: .9rem;
    font-family: 'press start 2p';
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 2px #000;
    font-weight: lighter;
    margin: 0 auto;
    line-height: 1rem;
    
}

.projects-title--container div a {
    color: #fff;
    padding: .2rem;
    font-size: 1.5rem;
    height: fit-content;
    width: fit-content;
    transition: all ease-in .3s;
}

.projects-title--container div a i svg path, .projects-title--container div a i svg, .projects-title--container div a i{
    height: fit-content;
    width: fit-content;
}

.projects-title--container div a:hover {
    color:  #11e8ff;
    transform: scale(1.1);
}

.projects-title--container p {
    font-family: var(--main-fontFamily);
    text-align: center;
    font-size: .9rem;
    color: white;
    text-decoration: underline;
    padding: 0 0 .2rem 0;
}

.projects-subtitle {
    font-family: 'press start 2p';
    color: #000;
    font-size: .8rem;
    text-shadow: 1px 1px 2px rgb(5, 207, 207);
    margin: 1rem 1rem 1rem 0;
    font-weight: lighter;
    background-color: var(--main-bgColorLightGrey);
    width: fit-content;
    padding: 1px 2px;
}

.projects-description--container {
    margin: 0 .5rem;
    font-family: var(--main-fontFamily);
    color: var(--main-textColor);
    list-style-type: none;
    text-align: justify;
}

.projects-description--container ul {
    list-style-type: square;
    list-style-position: inside;
    font-size: .9rem;
}

.projects-description--container ul li::marker{
    color: rgb(5, 207, 207);
}

.projects-description--container ul li{
    margin-bottom: .5rem;
}

/* .projects-subdescription--container {
    margin: 0 1rem 1rem 1rem;
} */

.projects-subdescription--container p {
    color: var(--main-textColor);
    font-size: .9rem;
    text-align: justify;
    font-family: var(--main-fontFamily);
    line-height: 1.2rem;
    margin: 0 .5rem;
}

.wrapper-projects hr {
    margin: 1rem;
}

.subContainer-bottom {
    border: 4px ridge #000;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-bottom: .5rem;
}

.-spanOfLinkAkitaDesbAlgTwitter {
    display: block;
    font-size: .8rem;
    font-family: var(--main-fontFamily);
    color: #11e8ff;
    margin: 3px 0;
    text-align: center;
    text-shadow: 1px 1px 1px black;
}

a.-linkBoldYellowProjects {
    color: #ffd700;
    font-weight: bold;
    font-size: .7rem;
}

code.subtituloOfSingleProject {
    background-color: var(--main-bgColorLightGrey);
    font-family: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
    color: #000;
    font-weight: bolder;
    padding: 0 1px;
    font-size: .8rem;
    text-shadow: 1px 1px 2px rgb(5, 207, 207);
    line-height: 1rem;
}

code.subtituloOfSingleProject.--dateOfImplementation {
    font-size: .9rem;
    margin: 3px 0;
}




@media (max-width: 810px)
{
    .projects-title {
        font-size: .8rem;     
    }
    .projects-title--container p {
        font-size: .7rem;
    }
    .projects-title--container > span {
        font-size: .7rem;
    }
    .projects-subtitle {
        font-size: .8rem;
    }
    .projects-description--container {
        font-size: .7rem;
    }

    /* .projects-subdescription--container p {
        max-width: 90%;
    } */

    .projects-logo {
        max-width: 100%;
        margin: 0 auto;
    }
    
    .projects-right {
        max-width: 100%;
        margin: 0 auto;
    }

   code.subtituloOfSingleProject {
        font-size: .7rem;
    }
}

@media (max-width: 425px)
{
    .projects-title {
        font-size: .6rem;     
    }
    .projects-title--container div a {
        font-size: 1.3rem;
    }
    .projects-title--container p {
        font-size: .7rem;
    }
    
    .projects-subtitle {
        font-size: .6rem;
    }
    
    .projects-description--container {
        font-size: .6rem;
    }

    .projects-subdescription--container p {
        font-size: .8rem;
        /* max-width: 90%; */
    }
    .projects-description--container ul {
        font-size: .8rem;
    }
    .projects-title--container > span {
        font-size: .64rem;
    }

    a.-linkBoldYellowProjects {
        color: #ffd700;
        font-weight: bold;
        font-size: .6rem;
    }

    #main-projects--container {
        padding-right: 0px;
    }

    code.subtituloOfSingleProject.--dateOfImplementation {
        font-size: .65rem;
        border-radius: 0;
        line-height: .7rem;
    }

    .projects-description--container h3.projects-subtitle {
        text-shadow: 1px 1px 1px rgb(5, 207, 207);
        padding: 1px 2px;
    }
}