* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Genshin Impact Font Family';
  src: url('/home/welbert/Documents/github/First_Portifolio_GithubPages_2022/src/resources/fonts/zh-cn.ttf');
}

:root {
  --main-color: rgb(13 159 13) !important;
  --second-color: black !important;
  --main-fontFamily: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
  --main-titleColor: #2f81f7 !important;
  --main-textColor: #d1d5db !important;
  --main-fontfamily-Press2PLight: Söhne Mono,Monaco,Andale Mono,Ubuntu Mono,monospace!important;
  --main-bgColorLightGrey: #a9d2d7;
  --main-fontFamily-secondTitle: 'Genshin Impact Font Family';
}

a {
  text-decoration: none;
}

a.-linkBoldYellow {
  color: #ffd700;
  font-weight: bold;
}

.-BoldYellow {
  color: #ffd700;
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-size: 68%;
  overflow-x: hidden;

  --tw-bg-opacity: 1;
  background-color: #000;
}


